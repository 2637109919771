.aboutus-main{
   margin-top: 110px;
   margin-bottom: 80px;
}
.aboutus-image{
    width: 40vw;
    height: 300px;
    object-fit: cover;
}

.aboutus-main_page{
    display: flex;
    padding: 10px;
}


.aboutus-readmore_btn:hover{
   color: white;
}

.aboutus-aboutus_text{
    position:absolute;
    margin:40px 70px;
    color:white;
    font-size:4vw;
}

.aboutus-desc_container{
    padding:40px 80px;
}

@media screen and (max-width:1050px){

    .aboutus-image{
        width: 75%;
    }
    .aboutus-main_page{
        flex-direction: column;
        align-items: center;
    }

    .aboutus-main{
       text-align: center;
    }

    .aboutus-aboutus_text{
        position: relative;
        color: black;
        margin: 0;
        margin-bottom: 30px;
        /* margin-top: 30px; */
        font-size: 30px;
    }

    .aboutus-desc_container{
        padding:20px 5.5vw;
    }
    
}


@media screen and (max-width:400px){
    .aboutus-main_page{
      margin: 0;
    }
  }

