.aboutuscontact-image{
    width: 100%;
    object-fit: cover;
    height: 300px;
}


.aboutuspage-contactus{
    display:flex;
    align-items:center;
    justify-content:center;
    margin:30px 0;
}

.contact-aboutus{
    -webkit-box-shadow: 4px 4px 12px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    4px 4px 12px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         4px 4px 12px 0px rgba(50, 50, 50, 0.75);
    color:white;
}


.aboutuspage-contactus-text{
    width:50%;
    margin-right:5%;
}

.aboutuspage-contactus-thebutton{
    display:flex;
    flex-direction:column;
    width:30%;
    min-height:250px;
    background-color:#515A5A;
    align-items:center;
    justify-content:center;
    color:white;
}

@media screen and (max-width:920px){
    .aboutuspage-contactus{
        flex-direction: column;
        margin: 0;
    }

    .aboutuspage-contactus-text{
        width: 80%;
        margin-right: 0%;
    }

    .aboutuspage-contactus-thebutton{
        width: 60%;
    }

}