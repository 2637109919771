 .marker{
    position: absolute;
    transform: translate(-50%,-100%);
} 

.strech1{
    width: 48%;
}


.strech2{
    width: 50%;
}

.location-place{
    font-size: 30px;
    list-style-type: none;
}

@media screen and (max-width:1120px){
    .our-location_main{
        flex-direction: column;
    }

    .our-location-strech{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .strech1{
        display: block;
        text-align: center;
    }
}

