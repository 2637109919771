.ourprojects-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ourprojects-button{
    border: 2px solid black;
    padding: 8px 10px;
    cursor: pointer;
    transition: 0.3s ease-in;
}

.seeallprojects{
    text-decoration: none;
    color: black;
    transition: 0.3s ease-in;
}

.ourprojects-button:hover{
    border: 2px solid #F1C40F;
}

.ourprojects-button:hover > .seeallprojects{
    color: #F1C40F;
}



.ourproject-image_cont{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill);
    margin: 50px 0;
    place-items: center;
}

.ourproject-image_single{
    width: 300px;
    height: 300px;
    transition: 1s ease-in-out;
    object-fit: cover;
}


.project-title{
    position: absolute;
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
    transition: 1s ease-in-out;
}

.ourproject-imageModalItem{
    width:400px;
    height:300px;
    object-fit:cover;
}

.ourproject-image_single_container:hover .project-title{
    opacity: 1;
    background-color: rgba(220, 118, 51,0.6);
}
.ourproject-image_single_container{
    max-width: 300px;
    max-height:300px;
    overflow: hidden;
}

.ourproject-image_single_container:hover .ourproject-image_single{
    transform: scale(1.2);
}


.ourproject-imageModal{
    top:20%;
}

@media screen and (max-width:500px){
    .ourproject-imageModalItem{
        width: 220px;
        height: 200px;
    }

    .ourproject-image_cont{
        margin: 0;
        margin-top: 15px;
    }

}

@media screen and (max-width:900px){
    .ourproject-imageModal{
        display: none;
    }
}
