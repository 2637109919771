.contactus-container{
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.contactus-main{
    padding-bottom: 30px;
}

.contactus-field_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.aboutus-contact_form_label{
    font-weight: bold;
    font-size: 30px;
    display: flex;
    justify-content: flex-start;
}

.contactus-input{
    padding:5px 10px;
    font-size: 20px;
    border:none;
    width: 400px;
    border-bottom: 1px solid grey;
    margin:0 60px;
    outline: none;
}

.contactus-message{
    resize: none;
    min-height: 80px;
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
}

.contactus-input:focus{
    border-bottom: 1px solid green;
}

.contactus-button{
    background-color:red;
    height:30px;
    margin-top:40px;
    margin-right:40px;
    padding:3px 20px;
    border-radius:30px;
    cursor:pointer;
    outline: none;
    color: white;
    border: 1px solid white;
}

@media screen and (max-width:800px){
    .aboutus-contact_form_label{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
   
    .contactus-input{
        margin:10px 0px;
        width: 80vw;
    }
    .contactus-field_container{
        align-items: center;
    }

}