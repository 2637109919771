.itemdetails_itemGrid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    padding: 30px 0;
    width: 100%;
    justify-items: center;
}

.itemdetails-main{
    overflow: hidden;
    flex: 1;
}

.itemdetails-imageStack-container{
    flex:0.3;
    width:400px;
}
.itemDetails-description{
    align-items: center;
}

.itemdetails-imageStack{
    margin-left: 80px;
    object-fit:cover;
    width:400px;
    height:300px;
}

.itemdetails-gallery{
    display: flex;
    overflow-x: scroll;
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
}


.itemdetails-gallery > img{
    width:200px;
    height:200px;
    margin:10px 0;
    object-fit: cover;
    padding: 0 30px;
}

.itemdetails-gallery::-webkit-scrollbar {
    height: 10px;
  }

  .itemdetails-gallery::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    
  }

  .itemdetails-gallery::-webkit-scrollbar-thumb {
    background: #85C1E9; 
    border-radius: 10px;
  }

  .itemdetails-gallery::-webkit-scrollbar-thumb:hover {
    background: #3498DB; 
  }



@media screen and (max-width:900px){
    .itemdetails_itemGrid{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width:1080px){
    .itemDetails-description{
        flex-direction: column;
        align-content: center;
    }

    .itemdetails-imageStack{
        margin-left: 0;
    }
}

@media screen and (max-width:400px){
    .itemdetails-imageStack-container{
        width: 280px;
        overflow: hidden;
    }

    .itemdetails_itemGrid{
        grid-template-columns: repeat(1,1fr);
        place-items: start;
        transform: translateX(30%);
    }
}