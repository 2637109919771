.head-main{
    position: relative;
    height: 700px;
}


.head-theopacity{
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}

.head-col{
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    margin: 10px;
    padding: 5px 8px;
    cursor: pointer;
}


.head-services_slider{
    display:flex;
    justify-content: center;
}

.head-services_slider_item{
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 8px 0;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    width: 200px;
}

.head-contact{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    z-index: 999;
    top: 0;
    background-color: #5B51D8;
}


.head-contac_phone_email{
    display: flex;
    align-items: center;
}

.head-social_networks{
    display: flex;
    align-items: center;
    margin-left: 100px;
}

.head-colorized{
    color: whitesmoke;
    font-size: 1.3vw;
}
.head-colorized-icon{
    color: #F1C40F;
}


.head-menu-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content:flex-end;
}



.header-social_contact:hover{
    color: #3498DB;
    cursor: pointer;
}

.head-menu{
    display: inline-block;
    margin:0 5px;
}

.head-menu p{
    text-decoration: none;
    font-size: 1.6vw;
    font-weight: bolder;
    color:black;
    padding: 5px 8px;
    width: auto;
    cursor: pointer;
}


.head-menu p:hover{
    color: red;
    text-decoration: underline;
    transition: 0.3s ease-out;
}

.header-menu_main{
    margin-right: 50px;
}

.head-image_slider{
    min-width: 100%;
    max-width: 100%;
    height: 500px;
    object-fit: cover;
    position: absolute;
    z-index: -10;
}

.header-arrow{
    position: absolute;
    color: white;
    top: 60%;
    cursor: pointer;
    background-color: black;
    border-radius: 50%;
    opacity: 0.7;
    z-index: 100;
}

.right{
    right: 0;
}

.header-slider{
    opacity: 0;
    transition: 1s ease-in-out;
    transform: scale(0.9) ;
}

.header-slider.active{
    opacity: 1;
    transition-duration: 0.5s;
    transform: scale(1);
} 

.next{
    transform: translateX(-150px);
}

.prev{
    transform: translateX(150px);
}


.header-arrow:hover{
    opacity: 1;
    transition: 0.2s ease-in-out;
}

.head-content-slider{
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
}

.header-title_omega{
    color: #F1C40F;
    font-size: 2.5vw;
}

.header-logo_omega{
    width:11vw;
}

.header-menu_icon{
    display: none;
}

@media screen and (max-width:1180px){
    .head-menu p{
        font-size: 15px;
    } 
}

@media screen and (max-width:1050px){
    .header-arrow{
        top: 70%; 
    }
    .head-menu-container{
        flex-direction: column;
    }
    .head-menu p{
        font-size: 2.3vw;
    } 
    .header-title_omega{
        font-size: 30px;
    }
    .header-logo_omega{
        width:150px;
    } 

    .head-main{
        position: relative;
        height: 800px;
    }
   
}

@media screen and (max-width:940px){
   .head-contact{
       flex-direction: column;
   }

   .head-colorized{
    font-size: 1.8vw;
        }
        
        .head-main{
            position: relative;
            height: 840px;
        }

       
}

.head-item1{
    width:100%;
    display:flex;
    flex-direction:column;
    color:white;
    align-items:center;
    justify-content: center;
    height: 500px;
}


@media screen and (max-width:850px){

    .we-are-available{
        display: none;
    }
   
    .head-image_slider{ 
        height: 300px;
    }

    .head-theopacity{
        height: 300px;
    }

    .head-item1{
        height: 300px;
    }

    .head-item2{
       margin-top: -150px;
        font-size: 28px;
    }

    .head-image_medal{
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
    
   .head-contac_phone_email{
       flex-direction: column;
       align-items: flex-start;
   }
   .header-arrow{
    top: 80%; 
}

   .head-contact{
    position: relative;
    top:0;
   }
   .head-menu-container{
    margin-top:0;
}
.head-colorized{
    font-size: 16px;
    padding:0px;
    margin:10px 0px;
        }


  .head-main{
    position: relative;
    height: 680px;
}

 }


 @media screen and (max-width:750px){

    .header-navbar{
        height: 0;
        overflow: hidden;
        transition: 1s ease-in-out;
     }


     .header-navbar.isActive{
        height: 190px;
        transition-duration: 1s;
    }
    
     .header-menu_main{
        display: flex;
        flex-direction: column;
        margin-top: -10px;
     }

     

     .header-menu_icon{
         display: flex;
         align-items: center;
         width: 100%;
         justify-content: flex-start;
     }
    
     .head-menu p{
         font-size: 22px;
         text-decoration: underline;
         color: #F1C40F;
         height: 20px;
         margin: 0;
     }

     .head-menu{
         margin: 3px;
     }
     
     .header-arrow{
        margin-top: 0px; 
        transition: 1s ease-in-out;
    }

     .iconActive{
        transition-duration: 1s;
     }

     .head-main{
         height: 650px;
         transition: 1s ease-in-out;
     }
     .mainActive{
         height: 850px;
         transition-duration: 1s;
     }
     
 }


.discount{
    font-size: 40px;
    animation: animate-discount-color 0.2s infinite;

}

@keyframes animate-discount-color{
    0%{
        color: white;
    }
    70%{
        color: red;
    }

}