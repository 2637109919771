.services-main{
    /* padding: 20px; */
    background-color: rgba(254, 249, 231,0.6);
    margin: 0;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-item_image{
    width: 250px;
    height: 150px;
    object-fit: cover;
    object-position: center;
}

.services-item{
    display: flex;
}

.services-item_right{
    display: flex;
    align-content: flex-end;
    margin-top: 15px;
    margin-bottom: 15px;
}


.service-colorized{
    color: black;
    
}

.services-item_content{
    padding: 0 200px 0 30px;
}

.services-item_content_right{
    padding: 0 30px 0 200px;
}

.services-learnmore_btn:hover{
        color: #F39C12;
}

.service-product_title{
    display:flex;
    align-items:center;
}

.service-right{
    justify-content:flex-end;
}


@media screen and (max-width:1050px){
        .services-item{
            flex-direction: column;
            align-items: center;
        }

        .services-item_right{
            flex-direction: column-reverse;
            align-items: center;
        }
        
        .services-item_content_right{
            padding: 0 30px;
           
        }

        .services-item_content{
            padding: 0 30px;
        }

        .service-title{
            width: 100%;
            text-align: center;
        }

        .service-product_title{
            width: 100%;
            justify-content: center;
        }
}

