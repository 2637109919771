.footer-main{
    background-color: #5B51D8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-social_networks{
    color: white;
    padding: 18px;
    font-size: 30px;
    cursor: pointer;
}

.footer-social_networks:hover{
    color:#2E86C1;
    transition: 0.5s ease-out;
}