.opinion-main{
    background-color: rgba(255, 231, 242, 0.23);
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    padding-bottom: 15px;
}
.opinion-main > h1{
    margin-left: 2%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.opinions-commentContainer > p{
    margin: 0;
    padding: 5px;
}

.opinions-commentContainer{
    max-width: 370px;
    padding: 10px 10px;
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    margin: 10px;
    border-radius: 20px;
}

.opinions-image{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 18px;
}


.opinion-scroller{
    display: flex;
    overflow-x: scroll;
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}


.opinion-scroller::-webkit-scrollbar {
    display: none;
  }