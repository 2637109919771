.cservices-all_container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto-fill);
}


.cservices-lists{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3px;
    background-color: white;
}

.cservices-item{
    font-size: 15px;
    display: flex;
    align-items: center;
}

ul.cservices-list_services {
    list-style-type: none;
    padding-left: 0;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    margin: -16px 0px;
    font-size: 0;
}

 .thebg{
    background-image: url(https://images.unsplash.com/photo-1515852216175-927860d2be80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    }

    .cservices-desc{
        display:flex;
        flex-direction:column;
        align-items:center;
        background-color:white;
        padding:20px;
        width:200px;
        margin:0 10px;
        box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12);
    }

@media screen and (max-width:1100px){
    .cservices-all_container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill);
    }
      
}

@media screen and (max-width:650px){
    .cservices-all_container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill);
    }
      
}

@media screen and (max-width:400px){
    .cservices-all_container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(auto-fill);
    }

    .cservices-desc_container{
        flex-direction: column;
        align-items: center;
    }

    .cservices-desc{
        margin: 20px 0;
    }
      
}